@tailwind base;
@tailwind components;
@tailwind utilities;

/* English Font */
@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Bold.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Bold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM_Font";
  src: url("./assets/fonts/en/IBMPlexSansArabic-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

/* Arabic Font */
@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-ExtraBold.otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "29LTBukra";
  src: url("./assets/fonts/ar/29LTBukra-Light.otf");
  font-weight: 300;
  font-style: normal;
}

:root {
  --primary-color: #27a8e0;
  --secondary-color: #fc4a17;
  --black-color: #191920;
}

* {
  margin: 0;
  padding: 0;
  font-family: "IBM_Font", sans-serif !important;
}

body {
  font-family: "29LTBukra", sans-serif !important;
  color: #f0f0f0;
}

a {
  text-decoration: none;
}

a:hover {
  color: inherit;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: var(--primary-color);
}

.logo {
  height: 32px;
  margin: 16px;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}

.ant-layout-sider-collapsed .lg-logo {
  display: none;
}

.logo {
  background-color: transparent;
  margin: 20px 0;
  min-height: 64px;
}

main {
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
}

.header-section {
}

main::-webkit-scrollbar {
  width: 0px;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}

.ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
}

.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}

.error {
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
}

/* Loader */
.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* dashbord css */
.dashboard-container {
  min-height: 100vh;
}

.dashboard-container-title {
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 140% */
}

.title {
  font-size: 26px;
  font-style: normal;
}

.w-fit {
  width: fit-content;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eaeaea;
}

::-webkit-scrollbar-thumb {
  background-color: #2c365b;
  cursor: pointer;
}

/* Custom Select */

.custom-select-wrapper {
  /* Disabled state */
  cursor: not-allowed;
}

.custom-select-wrapper:disabled {
  border: 1px solid rgba(15, 17, 52, 0.25);
  background: #f0f0f0;
  cursor: not-allowed;
}

.cm-select-container {
  border-color: transparent !important;
  background-color: #1f2937 !important;
}

.cm-select__value-container {
  background-color: #1f2937 !important;
  border-color: transparent !important;
  outline: none !important;
  padding: 12px 12px !important;
  color: #fff !important;
}

.cm-select__value-container {
  background-color: #1f2937 !important;
  border-color: transparent !important;
  outline: none !important;
  padding: 12px 12px !important;
  color: #fff !important;
}

.cm-select__single-value {
  color: #fff !important;
}

.cm-select__value-container input {
  color: #fff !important;
}

.cm-select__indicators {
  background-color: #1f2937;
  border-color: transparent !important;
}

.cm-select__control {
  color: #fff !important;
  font-size: 1rem !important;
  width: 100% !important;
  transition: all 0.3s ease;
  border: 1px solid #1f2937 !important;
  box-shadow: none !important;
  background-color: #0f1134;
  outline: none !important;
}

.cm-select__control--is-focused {
  outline: none !important;
  box-shadow: none !important;
}

.cm-select__control--menu-is-open {
  border-color: #09cfab !important;
}

.cm-select__control:active,
.cm-select__control:hover {
  outline: none !important;
  box-shadow: none !important;
}

.cm-select__control::placeholder {
  opacity: 0.5 !important;
  font-weight: 300 !important;
}

.cm-select__control:disabled {
  border: 1px solid rgba(15, 17, 52, 0.25);
  background: #f0f0f0;
  box-shadow: 0px 0px 10px 0px rgba(114, 159, 197, 0.1);
  cursor: not-allowed;
}

.cm-select__indicator svg path {
  fill: #707070;
}

.cm-select__multi-value {
  border-radius: 0.325rem;
  padding: 0.25rem;
  color: #fff !important;
}

.cm-select__multi-value-label {
  color: #fff !important;
  font-weight: 800;
}

.cm-select__indicator-separator {
  display: none;
}

.cm-select__menu-list {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #1f2937;
  color: #fff;
}

.hide-scroll {
  overflow: hidden;
}

.cm-select__menu .cm-select__menu-list .cm-select__option {
  position: relative;
  padding-right: 2rem;
  padding: 2rem auto;
  font-weight: 400;
  letter-spacing: 0.0125rem;
}

.cm-select__menu .cm-select__menu-list .cm-select__option {
  color: #fff;
}

.cm-select__menu .cm-select__menu-list .cm-select__option::placeholder {
  opacity: 0.5 !important;
}

.cm-select__menu
  .cm-select__menu-list
  .cm-select__option.cm-select__option--is-focused {
  background: rgba(240, 240, 240, 0.1);
}

.cm-select__menu
  .cm-select__menu-list
  .cm-select__option.cm-select__option--is-selected {
  background: rgba(255, 255, 255, 0.3);
  font-weight: 500;
}

/* Phone Input */
.PhoneInputInput {
  background-color: transparent;
  outline: none;
  font-size: 16px;
  margin: 0 6px;
}

@media print {
  .print-container {
    margin: 0;
    padding: 1in; /* Adjust the margin as needed */
    page-break-inside: avoid;
  }

  .qr-code-container {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .print-container::after {
    content: "";
    display: block;
    height: 1in; /* Adjust the space between pages as needed */
    page-break-after: always;
  }
}

.flip {
  transform: scaleX(-1);
}

.highcharts-credits {
  display: none;
}
